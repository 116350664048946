import { Logo } from "@/widgets";
import * as S from "./styles";
import HeaderButton from "./HeaderButton";
import ProfileButton from "./ProfileButton";
import { useContext, useEffect, useMemo, useState } from "react";
import router, { useRouter } from "next/router";
import { customerInitials } from "@/utils";
import { routerI18nHome } from "@/utils/router-i18n";
import { hasCookie } from "cookies-next";
import { TOKEN_COOKIE_KEY } from "@/const";
import DropdownLanguage from "@/widgets/DropdownLanguage";
import { t } from "i18next";
import Link from "next/link";
import { useCards } from "@/store/features/cards";
import { useTranslation } from "react-i18next";
import { useCustomer } from "@/store/features/customer/customer-query";
import { ICustomer } from "@/models";
import { Skeleton } from "@/components/Skeleton";
import { AbilityContext } from "@/presentation/libs/casl/context";

interface MenuOptionsType {
  itemName: string;
  route: string;
  type?: "default" | "selected";
  isPrime?: boolean;
  active: boolean;
  isShow?: boolean;
  id?: string;
}

const HeaderMenu = () => {
  const { customer, isCompany, isPrime, isLoadingCustomer, isBasicPerson } =
    useCustomer();
  const [, setSelect] = useState("Exchange");
  const [isLogged, setIsLogged] = useState(false);
  const [openOptions, setOpenOptions] = useState(false);
  const { cards: allCards, isLoading: isLoadingCard } = useCards();
  const location = useRouter();
  const { i18n } = useTranslation();
  const ability = useContext(AbilityContext);

  const headerOptions = useMemo(() => {
    let menu: MenuOptionsType[] = [
      {
        itemName: t("loggedInHeader.exchange"),
        route: "/",
        active: location.route === "/",
        isShow: true,
        id: "data-test-home",
      },
      {
        itemName: t("loggedInHeader.cards"),
        route: allCards?.length === 0 ? "/cardshowcase" : "/card",
        active: location.route.includes("card"),
        isShow: ability.can("Cards", "All"),
        id: "data-test-cards",
      },
      {
        itemName: t("investmentsLayout.investments"),
        route: "/wallet",
        isPrime: true,
        active: location.route.includes("wallet"),
        isShow: ability.can("Investments", "All") && isPrime,
        id: "data-test-wallet",
      },
    ];

    return menu.filter((item) => item.isShow);
  }, [allCards, location.route, i18n.language, customer, isPrime, ability]);

  const handleMenuOptionClick = (itemName: string, route: string) => {
    setSelect(itemName);
    router.push(route);
  };

  useEffect(() => {
    setIsLogged(customer !== null && hasCookie(TOKEN_COOKIE_KEY));
  }, [customer]);

  return (
    <S.HeaderMenuContainer>
      {customer ? (
        <S.Content>
          <S.ContentLogoAndNav>
            <S.ContentLogo>
              <Link href={routerI18nHome()}>
                <Logo variant={"transfero-new"} />
              </Link>
            </S.ContentLogo>

            <S.HeaderButtons>
              {isLoadingCard && isLoadingCustomer && (
                <Skeleton height="40px" width="400px" />
              )}
              {!isLoadingCard &&
                !isLoadingCustomer &&
                headerOptions
                  .filter((menu) =>
                    isCompany || isBasicPerson
                      ? menu.itemName !== t("loggedInHeader.cards")
                      : menu
                  )
                  .map((item) => {
                    return (
                      <HeaderButton
                        key={item.itemName}
                        itemName={item.itemName}
                        id={item.id}
                        onClick={() => {
                          setOpenOptions(false);
                          handleMenuOptionClick(item.itemName, item.route);
                        }}
                        isPrime={item.isPrime}
                        type={item.active ? "selected" : "default"}
                      />
                    );
                  })}
            </S.HeaderButtons>
          </S.ContentLogoAndNav>

          <S.ContentNavAndDropdownLanguage>
            <DropdownLanguage isLogged={false} />
          </S.ContentNavAndDropdownLanguage>
        </S.Content>
      ) : (
        <DropdownLanguage isLogged />
      )}
      {isLogged && !isLoadingCustomer && (
        <ProfileButton
          name={customer?.fullName || ""}
          nickname={`@${customer?.nickname}`}
          openOptions={openOptions}
          onClick={setOpenOptions}
          customerLevel={customer?.profileLevel || ""}
          customer={customer as ICustomer}
          collapsed={false}
          initials={customerInitials(customer?.fullName || "")}
        />
      )}
    </S.HeaderMenuContainer>
  );
};

export default HeaderMenu;
